.footer {
  position:relative;
  padding:50px 0;
  width:100%;
  background-color:#003e67CC;
  color: #FFF;
  a, a:visited, a:active {
    color: #FFF;
    text-decoration: none;
    &:hover {
      color:#fff8;
    }
  }
  img {
    max-width:100%;
  }
  a .fb {
    background-image: url(./fb.svg);
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    height: 40px;
    width: 40px;
    margin: 15px 0;
  }
}