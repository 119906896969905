@import 'utils/variables.scss';
.parcours {
  cursor:pointer;
  .parcours-tile {
    img {
      aspect-ratio: 16/9;
      object-fit: cover;
      @include md {
        height:250px;
      }
      @include sm {
        height:180px;
      }
      @include xs {
        height:180px;
      }
    }
    &.mini {
      h3 {
        font-size:2em;
      }
    }
    h3 {
      @include sm {
        font-size:2em;
      }
      @include xs {
        font-size:2em;
      }
    }
    
  }
}
