@import 'utils/variables.scss';
.home {
  h1 {
    font-family:'Syncopate-Fab';
    font-size:100px;
    width:100%;
    overflow-x: hidden;
    @include lg {
      font-size:85px;
    }
    @include md {
      font-size:55px;
    }
    @include sm {
      font-size:45px;
    }
    @include xs {
      font-size:35px;
    }
    text-align:center;
    padding: 30px 0;
}
h3 {
  font-family:'Syncopate-Fab';
  font-size:50px;
  width:100%;
  overflow-x: hidden;
  @include lg {
    font-size:45px;
  }
  @include md {
    font-size:40px;
  }
  @include sm {
    font-size:30px;
  }
  @include xs {
    font-size:20px;
  }
  text-align:center;
  padding: 15px 0;
}
.edito {
    margin: 50px 0;
  }
}
