svg.fond g path {
display:inline;
fill:none;
fill-rule:evenodd;
stroke:#003e67CC;
stroke-width:1.79999995;
stroke-linecap:butt;
stroke-linejoin:miter;
stroke-miterlimit:4;
stroke-dasharray:none;
stroke-opacity:1;
}
svg.fond {
    position:absolute;
    width:100%;
    transform-origin: top left;
}
.canvas-container {
    position:fixed;
    width:100%;
    height:50%;
    bottom:50%;
    left:0;
}
.canvas {
    position:absolute;
    left: calc(50% - 1000px);
    bottom: 0;
    width:2000px;
    height:2000px;
    transform-origin: bottom center;
}
//xs
.xs svg.fond{
    transform: translate(-2500px,2400px) scale(4);
    transition: all 1s;
}
.xs svg.fond path{
    stroke:#003e67CC;
    stroke-width:2;
    transition: all 1s;
}
//sm
.sm svg.fond{
    transform: translate(-1500px,1050px) scale(3);
    transition: all 1s;
}
.sm svg.fond path{
    stroke:#003e67CC;
    stroke-width:2;
    transition: all 1s;
}
//md
.md svg.fond{
    transform: translate(-500px,1420px) scale(2);
    transition: all 1s;
}
.md svg.fond path{
    stroke:#003e67CC;
    stroke-width:2;
    transition: all 1s;
}
//lg
.lg svg.fond{
    transform: translate(-200px,1200px) scale(1.5);
    transition: all 1s;
}
.lg svg.fond path{
    stroke:#003e67CC;
    stroke-width:2;
    transition: all 1s;
}
//xl
.xl svg.fond{
    transform: translate(200px,1350px) scale(1);
    transition: all 1s;
}
.xl svg.fond path{
    stroke:#003e67CC;
    stroke-width:2;
    transition: all 1s;
}
//xxl
.xxl svg.fond{
    transform: translate(200px,1280px) scale(1);
    transition: all 1s;
}
.xxl svg.fond path{
    stroke:#003e67CC;
    stroke-width:2;
    transition: all 1s;
}
