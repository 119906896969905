@import 'shared/variables.scss';
.bloc {
  position:relative;
  .spacer {
    height:100px;
  }
  .titre {
    font-family:'GillSans-Light';
    font-size:100px;
  }
  .texte {
    font-family:'OpenSans-Regular';
    font-size:20px;
  }
  .player {
    position:sticky;
    background-color:#FFF0;
    top:0;
    margin:0;
    margin-left:-15px;
    margin-bottom: 2em;
    width:calc(100% + 30px);
    transition: box-shadow 0.5s;
    .controls {
      position:absolute;
      bottom:15px;
      left:15px;
      opacity:0;
      transition: opacity 0.5s;
    }
    wave {
      opacity:0;
    }
    .loader {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      background-color:$mainColor;
      pointer-events: none;
      transition: opacity 0.5s;
      z-index:1;
      border-radius:4px;
      display: flex;
      justify-content: center;
      align-items: center;
      color:#FFF1;
      font-size:50px;
    }
    &.ready .loader {
      opacity:0;
    }
    &.ready .controls, &.ready wave {
      opacity:1;
    }
    &.active {
      background-color:#FFF;
      box-shadow: 0 40px 20px -40px #0008;
    }
  }
  img {
    width:100%;
    max-height:500px;
    object-fit: contain;
    object-position: center;
    margin:30px 0;
  }
  .bloc-video .player {
    background-color:#000;
    border-radius: 4px;
    .video {
      height:400px;
    }
    .metadata {
      position:absolute;
      top:calc(100% + 5px);
      right:0px;
      padding:5px 10px;
      text-align:right;
      border-radius: 4px;
      background-color: #fff;
      font-size:0.9em;
    }
    &.active .metadata {
      box-shadow: 0 0 10px #0002;
    }
  }
  .bloc-audio .player {
    .metadata {
      padding:5px 10px;
      padding-left: 60px;
      text-align:right;
      border-radius: 4px;
      background-color: #fff;
      font-size:0.9em;
    }
  }
}
@include lg {
  .bloc {
    .titre {
      font-size:85px;
    }
    .bloc-video .player {
      .video {
        height:400px;
      }
    }
  }
}
@include md {
  .bloc {
    .titre {
      font-size:70px;
    }
    .bloc-video .player {
      .video {
        height:300px;
      }
    }
  }
}
@include sm {
  .bloc {
    .titre {
      font-size:60px;
    }
    .bloc-video .player {
      .video {
        height:250px;
      }
    }
  }
}
@include xs {
  .bloc {
    .titre {
      font-size:50px;
    }
    .bloc-video .player {
      .video {
        height:200px;
      }
      .metadata {
        top:100%;
      }
    }
  }
}
