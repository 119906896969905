@import 'utils/variables.scss';
body {
    margin:0;
    padding:0;
    font-family:'OpenSans-Regular';
    font-size:16px;
    width:100%;
    color:$mainColor;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html, body, #root {
  height:100%;
  min-height: var(--screenHeight, 100%);
  user-select: none;
  -webkit-touch-callout: none;                /* prevent callout to copy image, etc when tap to hold */
  -webkit-user-select: none;                  /* prevent copy paste, to allow, change 'none' to 'text' */
  -webkit-tap-highlight-color: transparent;
  background-color: #FFF;
}
.debug {
  position:fixed;
  top:0;
  left:0;
  color:#FFF;
  z-index:10000;
}
img.img-responsive {
  width:100%;
  &.ratio-16-9 {
    aspect-ratio: 16/9;
    object-fit: cover;
  }
}
.spacer {
  height:50px;
}
