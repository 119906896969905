@import 'shared/variables.scss';
.markdown-preview, .player-container .markdown-preview {
  font-family:'Roboto';
  .MuiTypography-h1 {
    color:$mainColor;
    font-family:'Roboto';
    font-size:50px;
    line-height: 1.5;
  }
  .MuiTypography-h2 {
    color:$mainColor;
    font-family:'Roboto';
    font-size:45px;
    line-height: 1.5;
  }
  .MuiTypography-h3 {
    color:$mainColor;
    font-family:'Roboto';
    font-size:40px;
    line-height: 1.5;
  }
  .MuiTypography-h4 {
    color:$mainColor;
    font-family:'Roboto';
    font-size:35px;
    line-height: 1.5;
  }
  .MuiTypography-h5 {
    color:$mainColor;
    font-family:'Roboto';
    font-size:32px;
    line-height: 1.5;
  }
  .MuiTypography-h6 {
    color:$mainColor;
    font-family:'Roboto';
    font-size:30px;
    line-height: 1.5;
  }
  strong {
    font-weight:800;
  }
}
