@font-face {
  font-family: 'Syncopate-Fab';
  src: url('fonts/Syncopate-Fab.eot?#iefix') format('embedded-opentype'),  url('./fonts/Syncopate-Fab.woff') format('woff'), url('fonts/Syncopate-Fab.ttf')  format('truetype'), url('fonts/Syncopate-Fab.svg#Syncopate-Fab') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSans-Regular';
  src: url('fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),  url('./fonts/OpenSans-Regular.woff') format('woff'), url('fonts/OpenSans-Regular.ttf')  format('truetype'), url('fonts/OpenSans-Regular.svg#OpenSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GillSans-Light';
  src: url('fonts/GillSans-Light.eot?#iefix') format('embedded-opentype'),  url('./fonts/GillSans-Light.woff') format('woff'), url('fonts/GillSans-Light.ttf')  format('truetype'), url('fonts/GillSans-Light.svg#GillSans-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GillSans-LightItalic';
  src: url('fonts/GillSans-LightItalic.eot?#iefix') format('embedded-opentype'),  url('./fonts/GillSans-LightItalic.woff') format('woff'), url('fonts/GillSans-LightItalic.ttf')  format('truetype'), url('fonts/GillSans-LightItalic.svg#GillSans-LightItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GillSans';
  src: url('fonts/GillSans.eot?#iefix') format('embedded-opentype'),  url('./fonts/GillSans.woff') format('woff'), url('fonts/GillSans.ttf')  format('truetype'), url('fonts/GillSans.svg#GillSans') format('svg');
  font-weight: normal;
  font-style: normal;
}
