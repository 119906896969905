@import 'shared/variables.scss';
.player-container {
  position:relative;
  height:100%;
  overflow-y:scroll;
  hr {
    border:1px solid #0001;
    border-top:none;
    margin:30px 0
  }
  .spacer {
    height:100px;
  }
  .player-header {
    position:relative;
    overflow-x: hidden;
    img {
      height:500px;
      @include md {
        height:400px;
      }
      @include sm {
        height:300px;
      }
      @include xs {
        height:200px;
      }
      object-fit: cover;
    }
    h1 {
      position:absolute;
      bottom:5px;
      left:15px;
      width:calc(100% - 30px);
      color:#FFF;
      text-shadow: 0 0 20px #000;
    }
  }
  .player-body {
    padding:0 15px;
    padding-bottom:50px;
  }
  .next-btn-container {
    position:absolute;
    bottom:15px;
    width:100%;
    text-align: center;
    color:#FFF;
    a {
      color:#FFF;
      text-decoration: none;
    }
  }
  h1 {
      font-family:'Syncopate-Fab';
      font-size:120px;
      width:100%;
  }
  .player-footer {
    position:relative;
  }
}
@include lg {
  h1 {
    font-size:100px;
  }
}
@include md {
  h1 {
    font-size:85px;
  }
  .player-header {
    img {
      height:400px;
    }
  }
}
@include sm {
  h1 {
    font-size:60px;
  }
  .player-header {
    img {
      height:300px;
    }
  }
}
@include xs {
  h1 {
    font-size:35px;
  }
  .player-header {
    img {
      height:200px;
    }
  }
}
.clearfix {
  clear:both;
}