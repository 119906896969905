$mainColor:#003e67CC;
$mainColorLight:#003e67aa;

// XSmall devices
@mixin xs {
  .player-container.xs {
       @content;
   }
}

// Small devices
@mixin sm {
  .player-container.sm {
       @content;
   }
}

// Medium devices
@mixin md {
  .player-container.md {
       @content;
   }
}

// Large devices
@mixin lg {
  .player-container.lg {
       @content;
   }
}

// Extra large devices
@mixin xl {
  .player-container.xl {
       @content;
   }
}
