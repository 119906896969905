.board {
  position:fixed;
  width:100%;
  height:50%;
  top:50%;
  left:0;
  display:flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  .pirena {
    font-family: "Syncopate-Fab";
    font-weight: lighter;
    font-size: 5vw;
    color:#003e67CC;
    opacity:0;
    transition: opacity 0;
  }
  .collectages {
    font-size: 6vw;
    font-family: "GillSans-Light";
    letter-spacing: 0.2em;
    color:#888;
    opacity:0;
    transition: opacity 0;
    text-align:center;
  }
  &.on {
    .pirena {
      transition: opacity 1s 1s;
      opacity:1;
    }
    .collectages {
      transition: opacity 1s 1.5s;
      opacity:1;
    }
  }
  &.off {
    .pirena {
      transition: opacity 0.5s 0s;
      opacity:0;
    }
    .collectages {
      transition: opacity 0.5s 0s;
      opacity:0;
    }
  }
}
.xs .pirena {
  font-size: 12vw;
  text-align: center;
  line-height:1.5;
}
.xs .collectages {
  font-size: 10vw;
  line-height:1.5;
}
.sm .pirena {
  font-size: 10vw;
  text-align: center;
  line-height:1.5;
}
.sm .collectages {
  font-size: 8vw;
  line-height:1.5;
}
.md .pirena {
  font-size: 7vw;
  text-align: center;
  line-height:1.5;
}
.md .collectages {
  font-size: 8vw;
  line-height:1.5;
}
.lg .pirena {
  font-size: 6vw;
  text-align: center;
  line-height:1.5;
}
.lg .collectages {
  font-size: 7vw;
  line-height:1.5;
}
.xl .pirena {
  font-size: 6vw;
  text-align: center;
  line-height:2;
}
.xl .collectages {
  font-size: 7vw;
  line-height:1.2;
}
